import { get, post, put, Delete } from "../axios";

// 会员列表-列表
export const memberBusinessList = (params) =>
  get("/web/member/business/list", params);

// 会员列表-新增
export const memberBusinessAdd = (params) =>
  post("/web/member/business/add", params);

// 会员列表-编辑
export const memberBusinessUpdate = (params) =>
  put("/web/member/business/update", params);

// 会员列表-删除
export const memberBusinessDelete = (id) =>
  Delete("/web/member/business/delete/" + id, "");

// 会员列表-详情
export const memberBusinessView = (id) =>
  get("/web/member/business/view/" + id, "");

// 会员列表-密码修改
export const memberBusinessPassword = (params) =>
  put("/web/member/business/password/update", params);

// 会员列表-状态修改
export const memberBusinessState = (params) =>
  put("/web/member/business/state/update", params);
